

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NvictaInput extends Vue {
  @Prop({ required: true })
  value!: number;

  @Prop({ required: false, default: false })
  disabled: boolean;

  @Prop({ required: false, default: 'number' })
  type: string;

  @Prop({ required: false })
  min: boolean;

  @Prop({ required: false })
  max: boolean;

  @Prop({ required: false })
  placeholder: string;

  @Prop({ required: false })
  theme: string;

  get inputType(): string {
    return this.type === 'date' ? 'text' : this.type;
  }

  get inputValue(): number | string {
    return this.type !== 'date' ? this.value : new Date(this.value * 1000).toLocaleString();
  }

  onInput(evt: any): void {
    let formattedValue;
    switch(this.type) {
      case 'number':
        formattedValue = Number(evt.target.value);
        break;
      case 'text':
      case 'password':
      case 'date':
        formattedValue = String(evt.target.value);
        break;
      case 'checkbox':
        formattedValue = Boolean(evt);
        break;
      default:
        throw new Error(`Unknown type of ${this.type}`);
    }
    this.$emit('input', formattedValue);
  }

  onBlur(evt: Event): void {
    this.$emit('blur', evt);
  }

  onChange(evt: Event): void {
    this.$emit('change', evt);
  }
}

