import {
  HydraulicsSimulatorData,
  HydraulicsSimulatorWellSettings,
  HydraulicsSimulatorBhaSettings,
  HydraulicsSimulatorMwdRunSettings,
  HydraulicsSimulatorDatapoint,
  HydraulicsSimulatorMutation,
} from './types';

import { queryEngine } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class HydraulicsSimulatorModule extends VuexModule {
  _loading = false;
  _hydraulicsSimulatorData: HydraulicsSimulatorData | null = null;

  get hydraulicsSimulatorLoading(): boolean {
    return this._loading;
  }

  get hydraulicsSimulatorData(): HydraulicsSimulatorData | null {
    return this._hydraulicsSimulatorData;
  }

  @Mutation
  setHydraulicsSimulatorLoading(loading: boolean): void {
    this._loading = loading;
  }

  @Mutation
  setHydraulicsSimulatorData(hydraulicsSimulatorData: HydraulicsSimulatorData): void {
    this._hydraulicsSimulatorData = hydraulicsSimulatorData;
  }

  @Action({ rawError: true })
  async runHydraulicsSimulation(payload: {
    well: HydraulicsSimulatorWellSettings;
    bha: HydraulicsSimulatorBhaSettings;
    mwdRun: HydraulicsSimulatorMwdRunSettings;
    datapoint: HydraulicsSimulatorDatapoint;
  }): Promise<void> {
    store.commit(HydraulicsSimulatorMutation.SET_LOADING, true);

    const resp = await queryEngine('simulate-hydraulics', payload);
    if(!_.isNil(resp)) {
      store.commit(HydraulicsSimulatorMutation.SET_DATA, resp);
    }

    store.commit(HydraulicsSimulatorMutation.SET_LOADING, false);
  }
}
