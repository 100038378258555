




















































































































import WitsDebuggerModal from '@/components/modals/WitsDebuggerModal.vue';
import NotificationsModal from '@/components/modals/NotificationsModal.vue';
import NotificationHistoryModal from '@/components/modals/NotificationHistoryModal.vue';
import DashboardPresetModal from '@/components/modals/DashboardPresetModal.vue';

import { Well } from '@/store/modules/well/types';
import { User, UserAction, UserMutation } from '@/store/modules/users/types';
import { AppMutation } from '@/store/modules/app/types';
import { WellDataAction } from '@/store/modules/well_data/types';
import { DepthDataAction } from '@/store/modules/depth_data/types';
import { WellId } from '@/store/modules/types';
import { PresetGaugesConfigAction } from '@/store/modules/tabs/gauges/types';
import { Field } from '@/components/selectors/SearchableSelector.vue';
import { SettingsOptionsName } from '@/views/WellSettings.vue';

import { DashboardPreset, DashboardPresetMutation } from '@/store/modules/dashboard_preset/types';

import store from '@/store';
import { isRouteAllowed, RouteName } from '@/permissions';

import { Component, Vue, Watch } from 'vue-property-decorator';

import ChevronLeft from '@/assets/icons/pure-icons/chevron-left.svg.pure';

import * as _ from 'lodash';

@Component({
  components: {
    WitsDebuggerModal,
    NotificationsModal,
    NotificationHistoryModal,
    DashboardPresetModal,
    ChevronLeft,
  },
})
export default class DashboardHeader extends Vue {
  notificationsModalActive = false;
  witsDebuggerModalActive = false;
  presetModalActive = false;
  selectedPresetName: string | null = null;
  presetSelectorKey = 0;

  get presetsNames(): string[] {
    return _.map(this.$store.getters.dashboardPresets, (preset: DashboardPreset) => preset.name);
  }

  get activeDashboardPreset(): string {
    return this.$store.getters.user.activePreset;
  }

  get user(): User {
    return this.$store.getters.user;
  }

  get visibleNotifications(): Notification[] {
    return this.$store.getters.visibleNotifications;
  }

  get isWitsDebuggerDisabled(): boolean {
    return !isRouteAllowed(RouteName.WELL_SETTINGS);
  }

  get isSettingsDisabled(): boolean {
    return !isRouteAllowed(RouteName.WELL_SETTINGS);
  }

  @Watch('user', { immediate: true })
  onUserChanges(): void {
    this.selectedPresetName = this.user.activePreset;
  }

  async refetchData(): Promise<void> {
    if(!this.$store.getters.liveMode) {
      // TODO: clarify, it's a way to refetch with a new projections
      const time = this.$store.getters.wellData.time;
      const timeBorders = [_.head(time), _.last(time)];

      const depth = this.$store.getters.depthData.correctedDepth;
      const depthBorders = [_.head(depth), _.last(depth)];

      await Promise.all([
        this.$store.dispatch(WellDataAction.FETCH_HISTORICAL_WELL_DATA, timeBorders),
        this.$store.dispatch(DepthDataAction.FETCH_HISTORICAL_DEPTH_DATA, depthBorders),
      ]);
    }
    await Promise.all([
      this.$store.dispatch(WellDataAction.FETCH_LIVE_WELL_DATA),
      this.$store.dispatch(DepthDataAction.FETCH_LIVE_DEPTH_DATA),
    ]);
  }

  closeNotificationsModal(): void {
    this.notificationsModalActive = false;
  }

  closeWitsDebuggerModal(): void {
    this.witsDebuggerModalActive = false;
  }

  onNotificationsClick(): void {
    this.notificationsModalActive = true;
  }

  onWitsDebuggerClick(): void {
    this.witsDebuggerModalActive = true;
  }

  displayPresetModal(): void {
    this.presetModalActive = true;
  }

  closePresetModal(): void {
    this.presetModalActive = false;
  }

  onPresetCreate(): void {
    this.presetSelectorKey++;
  }

  async onPresetSelect(presetName: Field): Promise<void> {
    if(!presetName.value) {
      await store.dispatch('alertError', {
        title: 'Preset',
        message: `Something gone wrong with the preset`,
      });

      return;
    }

    this.selectedPresetName = presetName.value;
    this.$store.commit(UserMutation.PATCH_USER, presetName.value);
    this.$store.dispatch(UserAction.UPDATE_USER, this.user);
    this.$store.dispatch(PresetGaugesConfigAction.FETCH_GAUGES_CONFIG);
    this.$store.commit(DashboardPresetMutation.SET_ACTIVE_PRESET, presetName.value);
    await this.refetchData();
  }

  get wells(): Well[] {
    return this.$store.getters.wells;
  }

  // TODO: return type
  get favorites() {
    if(this.$store.getters.user === undefined) {
      return [];
    }
    const favoriteIds = this.$store.getters.user.favoriteWells || [];

    return this.wells
      .filter((well: Well) => favoriteIds.find((id: WellId) => id === well._id))
      .map((well: Well) => ({ ...well, url: `/well/${well._id}` }));
  }

  get wellName(): string {
    return this.$store.getters.currentWell &&
      this.$store.getters.currentWell.name || '';
  }

  get wellId(): string {
    return this.$store.getters.currentWell &&
      this.$store.getters.currentWell._id || '';
  }

  get wellInfo(): string {
    const currentWell = this.$store.getters.currentWell;
    if(currentWell === undefined) {
      return '';
    }
    const currentOperator = _.get(currentWell, 'operator.name') || 'not defined';
    const rig = _.get(currentWell, 'rig.name') || 'not defined';
    const well = currentWell.name || 'not defined';
    const jobId = currentWell.jobId || 'not defined';
    return `${currentOperator} / ${rig} / ${well } / ${jobId}`;
  }

  get infoTooltipContent(): string {
    return 'currentOperator / rig / well / jobId';
  }

  get liveMode(): boolean {
    return this.$store.getters.liveMode;
  }

  onGoLiveClick(): void {
    this.$store.commit(AppMutation.TOGGLE_LIVE_MODE);
  }

  onPresetSettingsClick(): void {
    this.$router.push({
      path: `/well/:wellId/settings`,
      name: 'well-settings',
      params: {
        wellId: this.$store.getters.currentWellId,
        preselectedOption: SettingsOptionsName.PRESETS,
      },
    });
  }

  onWellSettingsIconClick(): void {
    this.$router.push(`/well/${this.$store.getters.currentWellId}/settings`);
  }

  get isGoLiveActive(): boolean {
    return this.liveMode;
  }
}
