


















import PlugWall from '@/components/PlugWall.vue';
import GeneralSetting from '@/components/WellSettingsParts/GeneralSettings.vue';
import BhaSettings from '@/components/WellSettingsParts/BhaSettings.vue';
import FormationTopsSettings from '@/components/WellSettingsParts/FormationTopSettings.vue';
import DataProcessorSettingsComponent from '@/components/WellSettingsParts/DataProcessorSettings.vue';
import WellSummarySetting from '@/components/WellSettingsParts/MwdRunSettings.vue';
import DrillingAdvisorSettingsComponent from '@/components/WellSettingsParts/DrillingAdvisorSettings.vue';
import DirectionalAdvisorSettingsComponent from '@/components/WellSettingsParts/DirectionalAdvisorSettings.vue';
import ExternalDataSourcesSettings from '@/components/WellSettingsParts/ExternalDataSourcesSettings.vue';
import AntiCollisionSettings from '@/components/WellSettingsParts/AntiCollisionSettings.vue';
import UploadCSV from '@/components/WellSettingsParts/UploadCSV.vue';
import PresetSettings from '@/components/WellSettingsParts/PresetSettings.vue';

import { Component, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';

import { ENVIRONMENT } from '@/config';

import * as _ from 'lodash';

export enum SettingsOptionsName {
  GENERAL = 'General',
  BHA = 'BHA',
  FORMATION_TOPS = 'Formation Tops',
  DATA_PROCESSOR = 'Data Processor',
  MWD_RUN = 'MWD Run',
  DRILLING_ADVISOR = 'Drilling Advisor',
  DIRECTIONAL_ADVISOR = 'Directional Advisor',
  EXTERNAL_DATA_SOURCES = 'External Data Sources',
  ANTI_COLLISION = 'Anti Collision',
  UPLOAD_CSV = 'Upload CSV',
  PRESETS = 'Preset settings',
}

type SettingsOptionType = {
  component: VueConstructor<Vue>;
  name: SettingsOptionsName;
};

type SettingsOptionsType = SettingsOptionType[];

@Component({
  components: {
    PlugWall,
    GeneralSetting,
    BhaSettings,
    FormationTopsSettings,
    DataProcessorSettingsComponent,
    DrillingAdvisorSettingsComponent,
    DirectionalAdvisorSettingsComponent,
    ExternalDataSourcesSettings,
    AntiCollisionSettings,
    UploadCSV,
    PresetSettings,
  },
})
export default class WellSettings extends Vue {
  selectedOptionName = SettingsOptionsName.GENERAL;

  settingsOptions: SettingsOptionsType = [
    { component: GeneralSetting, name: SettingsOptionsName.GENERAL },
    { component: BhaSettings, name: SettingsOptionsName.BHA },
    { component: FormationTopsSettings, name: SettingsOptionsName.FORMATION_TOPS },
    { component: DataProcessorSettingsComponent, name: SettingsOptionsName.DATA_PROCESSOR },
    { component: WellSummarySetting, name: SettingsOptionsName.MWD_RUN },
    { component: DrillingAdvisorSettingsComponent, name: SettingsOptionsName.DRILLING_ADVISOR },
    { component: DirectionalAdvisorSettingsComponent, name: SettingsOptionsName.DIRECTIONAL_ADVISOR },
    { component: ExternalDataSourcesSettings, name: SettingsOptionsName.EXTERNAL_DATA_SOURCES },
    { component: AntiCollisionSettings, name: SettingsOptionsName.ANTI_COLLISION },
    { component: UploadCSV, name: SettingsOptionsName.UPLOAD_CSV },
    { component: PresetSettings, name: SettingsOptionsName.PRESETS },
  ];

  get activeComponent(): VueConstructor<Vue> | null {
    const selectedOption = _.find(
      this.settingsOptions,
      (option: SettingsOptionType) => option.name === this.selectedOptionName
    );

    if(!selectedOption) {
      return null;
    }
    return selectedOption.component;
  }

  isOptionSelected(option: SettingsOptionsName): boolean {
    return this.selectedOptionName === option;
  }

  selectOption(option: SettingsOptionsName): void {
    this.selectedOptionName = option;
  }

  setPreselectedTab() {
    const option: SettingsOptionsName = this.$route.params?.preselectedOption as SettingsOptionsName;

    if(option) {
      this.selectOption(option);
    }
  }

  created(): void {
    // TODO: move to router or fetch required data
    if(this.$store.getters.currentWellId === undefined) {
      const wellId = this.$route.params.wellId;
      this.$router.push(`/well/${wellId}`);
    }

    this.setPreselectedTab();
  }
}
