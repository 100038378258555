






























































































































































































import ConfirmModal from '@/components/modals/global/ConfirmModal.vue';
import WellSettingsPartBase from './Index.vue';

import { DEFAULT_BHA_COMPONENT, DEFAULT_BHA } from '@/store/modules/bha/defaults';
import { Bha, BhaAction, BhaComponent } from '@/store/modules/bha/types';
import { WellAction } from '@/store/modules/well/types';
import { TableData } from '@/components/ui-kit/NvictaTable.vue';

import { emitEngineEvent } from '@/services/socket_service';
import { getTimeDifferenceWithNow } from '@/utils';

import { Component, Vue } from 'vue-property-decorator';

import * as _ from 'lodash';
import { Option } from '../selectors/SearchableSelector.vue';

// TODO: refactor
const HIDDEN_FIELDS = ['_id', 'wellId', 'flowRate', 'rpm', 'diffPressure', 'active', 'manual'];
const READONLY_FIELDS = ['startDate', 'endDate'];
const NUMBER_FIELDS = [
  'startDepth', 'endDepth', 'maxTorque', 'yield', 'maxDiffPressure', 'rpg', 'maxWOB', 'maxFlowRate', 'tfa', 'od', 'drillPipeUnitWeight',
  'id', 'length', 'sequence', 'unitWeight', // components
  'rotorOverallLength', 'rotorHeadLength', 'rotorHeadDiameter', 'eccentricity', 'rotorWeight', 'statorLength', // motor
  'statorTubeOD', 'statorTubeID', 'statorWeight', 'flowMin', 'flowMax', 'speedMin', 'speedMax', 'torqueSlope',
  'rpg', 'maxPressure', 'maxTorque', 'offBottomPressure', 'stallTorque', 'stallPressure',
];
const BOOLEAN_FIELDS = ['active', 'isFilled', 'pipeIsFilled'];
const OBJECT_FIELDS = ['bit', 'motor', 'components'];
const ARRAY_FIELDS = ['components'];
const DATE_FIELDS = ['startDate', 'endDate'];

const LAST_SYNC_TIME_UPDATE_INTERVAL = 1000; // ms

const BHA_FIELD_MAPPINGS = {
  wellId: 'Well ID',
  name: 'Name',
  startDepth: 'Start Depth [ft]',
  endDepth: 'End Depth [ft]',
  active: 'Active',
  drillPipeId: 'Drill Pipe ID [in]',
  drillPipeOd: 'Drill Pipe OD [in]',
  pipeIsFilled: 'Pipe Is Filled',
  drillPipeUnitWeight: 'Drill Pipe Unit Weight [lb/ft]',
  motor: 'Motor',
  components: 'Components',
  bit: 'Bit',
  startDate: 'Start Date',
  endDate: 'End Date',
  directionalSensorOffset: 'Directional Sensor Offset [ft]',
  pulserSensorOffset: 'Pulse Sensor Offset [ft]',
  gammaSensorOffset: 'Gamma Sensor Offset [ft]',
  toolfaceCrossover: 'Toolface Crossover [deg]',
  directionalSensorSN: 'Directional Sensor SN',
  gammaSensorSN: 'Gamma Sensor SN',
  gammaCorrectionFactor: 'Gamma Correction Factor',
  drillPipeTjOd: 'Drill Pipe TJ OD [in]',
  _id: '_id',
};

const MOTOR_FIELD_MAPPINGS = {
  motorId: 'Motor ID',
  manufacturer: 'Manufacturer',
  modelNumber: 'Model Number',
  rotorOverallLength: 'Rotor Overall Length [in]',
  rotorHeadLength: 'Rotor Head Length [in]',
  rotorHeadDiameter: 'Rotor Head Diameter [in]',
  eccentricity: 'Eccentricity',
  rotorWeight: 'Rotor Weight [lb]',
  statorLength: 'Stator Length [in]',
  statorTubeOD: 'Stator Tube OD [in]',
  statorTubeID: 'Stator Tube IN [in]',
  statorWeight: 'Stator Weight [lb]',
  flowMin: 'Min Flow [gpm]',
  flowMax: 'Max Flow [gpm]',
  speedMin: 'Min Speed [rpm]',
  speedMax: 'Max Speed [rpm]',
  torqueSlope: 'Torque Slope [ft.lb/psi]',
  rpg: 'RPG',
  maxPressure: 'Max Pressure [psi]',
  maxTorque: 'Max Torque [ft.lb]',
  offBottomPressure: 'Off Bottom Pressure [psi]',
  stallTorque: 'Stall Torque [ft.lb]',
  stallPressure: 'Stall Pressure [psi]',
  yield: 'Yield [deg/100ft]',
  flowRate: 'Flow Rate [gpm]',
  rpm: 'RPM',
  diffPressure: 'Differential Pressure [psi]',
  stages: 'Stages',
  lobes: 'Lobes',
  maxDiffPressure: 'Max Differential Pressure [psi]',
};

const BIT_FIELD_MAPPINGS = {
  type: 'Type',
  manufacturer: 'Manufacturer',
  tfa: 'TFA [in^2]',
  od: 'OD [in]',
  gradeIn: 'Grade In',
  gradeOut: 'Grade Out',
};

const BHA_COMPONENT_FIELD_MAPPINGS = {
  od: 'OD [in]',
  id: 'ID [in]',
  tjOd: 'TJ OD [in]',
  length: 'Length [ft]',
  unitWeight: 'Unit Weight [lb/ft]',
  type: 'Type',
  isFilled: 'Is Filled',
  sequence: 'Sequence',
};

const BHA_COMPONENT_TYPES = [
  'Drill Bit',
  'Hole Opener',
  'Circ Sub',
  'Crossover',
  'RSS',
  'MWD/LWD',
  'Motor',
  'Turbine',
  'Stabilizer',
  'Drill Collar',
  'Drill Pipe / HWDP',
  'Jar',
  'Accelerator',
  'Sub',
  'Liner',
  'Casing / Tubing',
  'Agitator',
  'Reamer',
  'Pulser',
  'Battery',
  'Directional Module',
  'Driver',
  'Interconnect',
  'EM',
  'Gamma',
  'Resistivity',
  'Helix',
  'MWD Laptop',
  'MWD Depth Tracking',
  'MWD Decoder',
  'HASP Key',
  'Barrier Box',
  'Transducer',
  'Aggregator',
  'Surface Reciever',
  'MWD RFD',
  'Shock Absorber',
  'Other',
];

const BIT_TYPES = ['PDC', 'Roller Cone'];

@Component({ components: { ConfirmModal, WellSettingsPartBase } })
export default class BhaSettingsComponent extends Vue {
  changedBhaIds = [];
  expendedFormationTopId: string | null = null;
  componentKey = 0;
  isConfirmModalActive = false;
  toggleSyncSwitchKey = 0;
  toggleActiveSwitchKey = 0;
  syncActive = true;
  lastSyncTime = 'never';

  get bhaFieldMappings() {
    return BHA_FIELD_MAPPINGS;
  }

  get motorFieldMappings() {
    return MOTOR_FIELD_MAPPINGS;
  }

  get bitFieldMappings() {
    return BIT_FIELD_MAPPINGS;
  }

  get bhaComponentFieldMappings() {
    return BHA_COMPONENT_FIELD_MAPPINGS;
  }

  get bitTypes(): string[] {
    return BIT_TYPES;
  }

  get autoActiveBha(): boolean {
    return this.$store.getters.currentWell.autoActiveBha;
  }

  set autoActiveBha(auto: boolean) {
    this.$store.getters.currentWell.autoActiveBha = auto;
    const payload = {
      wellId: this.$store.getters.currentWellId,
      updatedFields: { autoActiveBha: auto },
      shouldRefetch: false,
    };
    this.$store.dispatch(WellAction.UPDATE_WELL, payload);
    // TODO: await
    emitEngineEvent('toggle-auto-active-bha', {
      wellId: this.$store.getters.currentWellId,
      autoActiveBha: auto,
    });
  }

  get autoSync(): boolean {
    return this.$store.getters.currentWell.bhaSynchronization;
  }

  set autoSync(isSync: boolean) {
    this.$store.getters.currentWell.bhaSynchronization = isSync;
    const payload = {
      wellId: this.$store.getters.currentWellId,
      updatedFields: { bhaSynchronization: isSync },
      shouldRefetch: false,
    };
    this.$store.dispatch(WellAction.UPDATE_WELL, payload);
    // TODO: await
    emitEngineEvent('sync-bhas', {
      wellId: this.$store.getters.currentWellId,
      bhaSynchronization: isSync,
    });
  }

  getItemDetailsStyle(detailsCount: number) {
    return {
      'grid-auto-flow': 'column',
      'grid-template-columns': '1fr 1fr',
      'grid-template-rows': `repeat(${Math.ceil(detailsCount / 2)}, 1fr)`,
    };
  }

  getComponentsTableData(components: BhaComponent[]): TableData[] {
    if(_.isEmpty(components)) {
      return [];
    }
    return _.map(components, (component: BhaComponent) => {
      const fieldValue = {};
      for(const field of this.getItemsKeys(component)) {
        fieldValue[field] = {
          value: component[field],
          type: this.getTableCellType(field),
          disabled: this.isDetailsInputDisabled(field),
        };

        if(field === 'type') {
          fieldValue[field].options = _.clone(BHA_COMPONENT_TYPES);
        }
      }
      return fieldValue;
    });
  }

  getComponentsFields(components: BhaComponent[]): { [itemKey: string]: string }[] {
    if(_.isEmpty(components)) {
      return [];
    }
    const itemKeys = this.getItemsKeys(components[0]);
    return itemKeys
      .map((itemKey: string) => {
        return { [itemKey]: this.bhaComponentFieldMappings[itemKey] };
      });
  }

  onBhaSyncToggle(option: boolean): void {
    if(option === true) {
      this.displayConfirmModal();
      return;
    }
    this.autoSync = false;
  }

  onSyncApply(): void {
    this.autoSync = true;
    this.closeConfirmModal();
  }

  updateLastSyncTime(): void {
    if(this.$store.getters.currentWell.lastBhasSync) {
      this.lastSyncTime = getTimeDifferenceWithNow(this.$store.getters.currentWell.lastBhasSync.time);
    }
  }

  onSyncCancel(): void {
    this.closeConfirmModal();
    this.autoSync = false;
  }

  closeConfirmModal(): void {
    this.toggleSyncSwitchKey++;
    this.isConfirmModalActive = false;
  }

  displayConfirmModal(): void {
    this.isConfirmModalActive = true;
  }

  async onApplyClick(): Promise<void> {
    await this.updateBhas();
  }

  async updateBhas(): Promise<void> {
    const changedBhas = _.filter(this.bhas, (item: Bha) => {
      return _.includes(this.changedBhaIds, item._id);
    });
    const bhas = _.map(changedBhas, (item: Bha) => this.getFormattedBha(item));
    const resp = await this.$store.dispatch(BhaAction.UPDATE_BHAS, bhas);
    if(!resp) {
      return;
    }
    await emitEngineEvent('bhas-updated', { wellId: this.wellId, changedBhas });
    await this.$store.dispatch('alertSuccess', { title: 'BHA settings', message: `Changes applied` });
    this.changedBhaIds = [];
  }

  async addBha(): Promise<void> {
    const newBha = _.cloneDeep(DEFAULT_BHA);
    newBha.wellId = this.$store.getters.currentWellId;
    await this.$store.dispatch(BhaAction.ADD_BHA, newBha);
    this.rerenderComponent();
  }

  async deleteBha(id: string): Promise<void> {
    await this.$store.dispatch(BhaAction.DELETE_BHA, id);
    await emitEngineEvent('bhas-updated', { wellId: this.wellId });
    this.rerenderComponent();
  }

  isDetailsActive(id: string): boolean {
    return this.expendedFormationTopId === id;
  }

  onBhaChange(id: string): void {
    if(_.includes(this.changedBhaIds, id)) {
      return;
    }
    this.changedBhaIds.push(id);
  }

  onBitTypeChange(bha: Bha, option: Option): void {
    bha.bit.type = option.value;
    this.onBhaChange(bha._id);
  }

  onBhaComponentChange(event: { value: number | string | boolean, field: string, rowIdx: number }, id: string): void {
    this.onBhaChange(id);
    const bha = _.find(this.bhas, (bha: Bha) => bha._id === id);
    bha.components[event.rowIdx][event.field] = event.value;
  }

  itemExpand(id: string): void {
    if(this.isDetailsActive(id) === true) {
      this.expendedFormationTopId = null;
      return;
    }
    this.expendedFormationTopId = id;
  }

  getItemsKeys(item: object): string[] {
    return _.keys(item).filter((item: string) => this.isFieldVisible(item));
  }

  isDetailsInputDisabled(field: string): boolean {
    if(this.$store.getters.currentWell.bhaSynchronization === true) {
      return true;
    }
    return _.includes(READONLY_FIELDS, field);
  }

  isFieldVisible(field: string): boolean {
    return !_.includes(HIDDEN_FIELDS, field) && !_.includes(OBJECT_FIELDS, field);
  }

  getFormattedBha(bha: any): any {
    const formattedBha = _.clone(bha);
    _.keys(bha).forEach((key: string) => {
      const value = bha[key];
      if(this.isDetailsInputDisabled(key) === true) {
        return;
      }
      if(_.includes(NUMBER_FIELDS, key) === true) {
        formattedBha[key] = Number(value);
        return;
      }
      if(_.includes(OBJECT_FIELDS, key) === true) {
        // TODO: refactor
        if(_.includes(ARRAY_FIELDS, key) === true) {
          formattedBha[key] = _.map(formattedBha[key], (item: Bha) => this.getFormattedBha(item));
          return;
        }
        formattedBha[key] = this.getFormattedBha(formattedBha[key]);
        return;
      }
      OBJECT_FIELDS;
    });
    return formattedBha;
  }

  getTableCellType(field: string): string {
    if(_.includes(NUMBER_FIELDS, field) === true) {
      return 'input-number';
    }
    if(_.includes(BOOLEAN_FIELDS, field) === true) {
      return 'checkbox';
    }
    if(field === 'type') {
      return 'selector';
    }
    return 'input-text';
  }

  getInputType(field: string): string {
    if(_.includes(NUMBER_FIELDS, field) === true) {
      return 'number';
    }
    if(_.includes(BOOLEAN_FIELDS, field) === true) {
      return 'checkbox';
    }
    if(_.includes(DATE_FIELDS, field) === true) {
      return 'date';
    }
    return 'text';
  }

  removeBhaComponent(bhaIdx: number, componentIdx: number): void {
    const components = this.bhas[bhaIdx].components;
    components.splice(componentIdx, 1);
    this.onBhaChange(this.bhas[bhaIdx]._id);
  }

  addBhaComponent(bhaIdx: number): void {
    const bha = this.bhas[bhaIdx];
    const defaultBhaComponent = _.clone(DEFAULT_BHA_COMPONENT);
    bha.components.push(defaultBhaComponent);
    this.onBhaChange(bha._id);
  }

  onMakeActiveClick(id: string): void {
    const bha = _.find(this.bhas, (bha: Bha) => bha._id === id);
    if(bha === undefined) {
      throw new Error(`Can't find BHA with id: ${id}`);
    }
    bha.active = true;
    this.onBhaChange(id);

    this.bhas
      .filter((bha: Bha) => bha._id !== id)
      .forEach((bha: Bha) => {
        if(bha.active) {
          bha.active = false;
          this.onBhaChange(bha._id);
        }
      });
  }

  get wellId(): string {
    return this.$store.getters.currentWellId;
  }

  get bhas(): Bha[] {
    const bhas = this.$store.getters.bhas;
    if(bhas === undefined) {
      return [];
    }
    return bhas;
  }

  // TODO: refactor
  // it's a hack: componentKey is used in :key on the top-level div inside the template
  // componentKey change forces the component to re-render
  rerenderComponent(): void {
    this.componentKey += 1;
  }

  mounted(): void {
    setInterval(this.updateLastSyncTime.bind(this), LAST_SYNC_TIME_UPDATE_INTERVAL);
  }
}
