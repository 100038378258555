




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import * as _ from 'lodash';

const DEFAULT_FORMATTER = (option: string) => option;
const DEFAULT_DISABLED_FORMATTER = (option: string) => false;
const DEFAULT_WIDTH = 150;
const DEFAULT_HEIGHT = 24;

@Component
export default class Selector extends Vue {
  @Prop({ required: true })
  options!: string[];

  @Prop({ required: false })
  initValue: string;

  @Prop({ required: false, default: () => DEFAULT_FORMATTER })
  labelFormatter: (option: string) => string;

  @Prop({ required: false, default: () => DEFAULT_FORMATTER })
  valueFormatter: (option: string) => string;

  @Prop({ required: false, default: () => DEFAULT_FORMATTER })
  keyFormatter: (option: string) => string;

  @Prop({ required: false, default: () => DEFAULT_DISABLED_FORMATTER })
  disabledFormatter: (option: string) => boolean;

  @Prop({ required: false, default: DEFAULT_WIDTH })
  width: number;

  @Prop({ required: false, default: DEFAULT_HEIGHT })
  height: number;

  @Prop({ required: false })
  staticPlaceholder: string;

  @Prop({ required: false, default: 'white' })
  labelColor: string;

  @Prop({ required: false, default: '#4f5252' })
  borderColor: string;

  model: string | null = null;

  @Watch('initValue')
  onInitValueChange(): void {
    // TODO: add v-model
    if(this.initValue !== undefined) {
      this.model = this.initValue;
    }
  }

  get selectorOptions(): string[] {
    if(this.staticPlaceholder === undefined) {
      return this.options;
    }
    const extendedOptions = _.clone(this.options);
    extendedOptions.push(this.staticPlaceholder);
    return extendedOptions;
  }

  optionStyle(option: string): { display: string, color: string } {
    // used as isOptionDisplayed
    const displayStyle = option === this.staticPlaceholder ? 'none' : 'block';
    return { display: displayStyle, color: this.labelColor };
  }

  onOptionSelect(): void {
    this.$emit('select-option', this.model);
    if(this.staticPlaceholder !== undefined) {
      this.model = this.staticPlaceholder;
    }
  }

  isDisabled(option: string): boolean {
    return this.disabledFormatter(option);
  }

  mounted(): void {
    if(this.initValue !== undefined) {
      this.model = this.initValue;
    }
    if(this.staticPlaceholder !== undefined) {
      this.model = this.staticPlaceholder;
    }
  }
}
