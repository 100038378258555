export const DEFAULT_BHA_COMPONENT = {
  od: 0.0,
  id: 0.0,
  tjOd: 0.0,
  length: 0.0,
  unitWeight: 23.0,
  type: '',
  isFilled: true,
  sequence: 0,
};

export const DEFAULT_BHA = {
  wellId: '',
  name: '',
  startDepth: 0.0,
  endDepth: 35000.0,
  active: false,
  drillPipeId: 3.86,
  drillPipeOd: 4.5,
  pipeIsFilled: true,
  drillPipeUnitWeight: 23.0,
  components: [{ ...DEFAULT_BHA_COMPONENT }],
  motor: {
    motorId: '',
    manufacturer: '',
    modelNumber: '',
    rotorOverallLength: 0.0,
    rotorHeadLength: 0.0,
    rotorHeadDiameter: 0.0,
    eccentricity: 0.0,
    rotorWeight: 0.0,
    statorLength: 0.0,
    statorTubeOD: 0.0,
    statorTubeID: 0.0,
    statorWeight: 0.0,
    flowMin: 0.0,
    flowMax: 0.0,
    speedMin: 0.0,
    speedMax: 0.0,
    torqueSlope: 0.0,
    rpg: 0.0,
    maxPressure: 0.0,
    maxTorque: 0.0,
    offBottomPressure: 0.0,
    stallTorque: 0.0,
    stallPressure: 0.0,
    yield: 0.0,
    flowRate: [100, 350],
    rpm: [[0, 1, 2], [0, 1, 2]],
    diffPressure: [[0, 10, 20], [0, 10, 20]],
  },
  bit: {
    type: '',
    manufacturer: '',
    tfa: 0.777,
    od: 6.666,
    gradeIn: '',
    gradeOut: '',
  },
  startDate: 0,
  endDate: 0,
  directionalSensorOffset: 65.0,
  pulserSensorOffset: 30,
  gammaSensorOffset: 45.0,
  toolfaceCrossover: 5,
  directionalSensorSN: 'snn',
  gammaSensorSN: 'snn',
  gammaCorrectionFactor: 1.0,
  drillPipeTjOd: 5.828,
};
