import { uid } from '@/utils';
import _ from 'lodash';

type TTargetCoords = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type TDirection = 'over' | 'under';

interface ITooltipOptions {
  direction?: TDirection;
  removeOnScroll?: boolean;
  removeOnClick?: boolean;
}

interface ITooltip {
  target: Node | null;
  targetCoords: TTargetCoords;
  tooltip: Node | null;
  classname: string;
  template: (content: string) => string;
  x: number;
  y: number;
  xOffset: number;
  yOffset: number;
  content?: string;
}

class VanillaTooltip implements ITooltip {
  target = null;
  _uid = uid();
  targetCoords = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  tooltip = null;
  classname = 'va-tooltip';
  template = (content: string = 'Insert Content') => (
    `<div class="${this.classname}" role="tooltip">
          <div class="${this.classname}__content">
              ${content}
          </div>
      </div>`
  );

  x = 0;
  y = 0;
  xOffset = 0;
  yOffset = 4;
  content = 'Insert Content';
  options: ITooltipOptions = {
    direction: 'over',
    removeOnScroll: false,
    removeOnClick: false,
  };

  onKeydownEvent = (event: KeyboardEvent) => this._onKeydown(event);
  onMouseMoveEvent = _.throttle((event: MouseEvent & { path: Node[] }) => this._onMouseMove(event), 200);
  onClickEvent = this.removeRelatives.bind(this);
  onResizeEvent = this.removeRelatives.bind(this);

  constructor(target, content, options?) {
    this.target = target;
    this.content = content;
    if(options) {
      this.options = Object.assign(this.options, options);
    }

    this._bindWindowEvents();
    this._bindTargetEvents();
  }

  get uid(): string {
    return this._uid;
  }

  getTargetCoords() {
    return {
      top: this.target.getBoundingClientRect().top,
      right: this.target.getBoundingClientRect().right,
      bottom: this.target.getBoundingClientRect().bottom,
      left: this.target.getBoundingClientRect().left,
    };
  }

  _onKeydown(event: KeyboardEvent) {
    if(event.key === 'Escape') {
      this._removeDuplicates();
      this._removeSelf();
      this._removeWindowEvents();
    }
    return event;
  }

  _onMouseMove(event: MouseEvent & { path: Node[] }) {
    if(!event?.path) {
      return;
    }
    if(!event.path.filter((node: Node) => node === this.target)) {
      this.removeRelatives();
    }
  }

  public removeRelatives(): void {
    this._removeDuplicates();
    this._removeSelf();
    this._removeWindowEvents();
  }

  _bindWindowEvents(): void {
    window.addEventListener('resize', this.onClickEvent);
    window.addEventListener('click', this.onResizeEvent);
    window.addEventListener('mousemove', this.onMouseMoveEvent);
    window.addEventListener('keydown', this.onKeydownEvent);
  }

  /**
   * Bind events on controls
   */
  _bindTargetEvents() {
    this.target.addEventListener('mouseover', (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      this.targetCoords = this.getTargetCoords();
      this._insertTemplate();
    });

    this.target.addEventListener('mouseout', () => {
      this._removeDuplicates();
      this._removeSelf();
      this._removeWindowEvents();
    });

    this.target.addEventListener('mousemove', (event: Event) => {
      return event;
    });

    if(this.options?.removeOnClick) {
      this.target.addEventListener('click', () => {
        this._removeSelf();
      });
    }

    if(this.options?.removeOnScroll) {
      this.target.addEventListener('mousewheel', () => {
        this._removeSelf();
      });
    }
  }

  /**
   * Insert content into a block
   * @param {Object} element - Block-wrapper.
   * @param {Object/string} content - Content to insert.
   */
  _insetContent(element: Element, content: Node | string) {
    if(typeof content === 'string') {
      element.insertAdjacentHTML('beforeend', content);
    } else if(typeof content === 'object') {
      element.appendChild(content);
    }
  }

  /**
   * Create and insert tooltip
   * @private
   */
  _insertTemplate() {
    const firstElement = 0;
    const template = this.template(this.content);
    this._insetContent(document.body, template);

    this.tooltip = document.querySelectorAll(`.${this.classname}`)[firstElement];

    const x = this._x(this.tooltip);
    const y = this._y(this.tooltip);

    this._position(x, y);
    this.tooltip.classList.add('show');
  }

  /**
   * Remove tooltip
   * @param {Node} element - element to remove
   */
  remove(element) {
    element.parentNode.removeChild(element);
  }

  /**
   * Remove self tooltip
   */
  _removeSelf() {
    if(this.tooltip?.parentNode) {
      this.remove(this.tooltip);
      this.tooltip = null;
    }
  }

  _removeWindowEvents() {
    window.removeEventListener('keydown', this.onKeydownEvent);
    window.removeEventListener('mousemove', this.onMouseMoveEvent);
    window.removeEventListener('click', this.onClickEvent);
    window.removeEventListener('resize', this.onResizeEvent);
  }

  /**
   * Remove tooltip's duplicates
   */
  _removeDuplicates() {
    if(this.tooltip?.parentNode) {
      this.tooltip.parentNode.querySelectorAll(`.${this.classname}`).forEach((dup: HTMLDivElement) => {
        if(dup !== this.tooltip) {
          this.remove(dup);
        }
      });
    }
  }

  /**
   * Hide tooltip
   * @param {Node} element - tooltip
   */
  hide(element) {
    element.style.display = 'none';
  }

  /**
   * Show tooltip
   * @param {Node} element - tooltip element
   */
  show(element) {
    element.style.display = 'block';
  }

  /**
   * Get tooltip coordinates
   * @param {Object} element - element to detect sides positions
   * @return {Object} - tooltip sides positions
   */
  _coords(element) {
    return element.getBoundingClientRect();
  }

  /**
   * Get tooltip's X coord
   * @param {Object} tooltip - dom element
   * @return {number} - x coord
   */
  _x(tooltip) {
    const targetWidth = this.target.offsetWidth || 0;
    const rawXLeftBorder = this.targetCoords.left + (targetWidth / 2) - (tooltip.offsetWidth / 2) - this.xOffset;
    const rawXRightBorder = rawXLeftBorder + tooltip.offsetWidth;

    if(rawXLeftBorder < 0) {
      return 0;
    } else if(rawXRightBorder > window.innerWidth) {
      return window.innerWidth - tooltip.offsetWidth;
    } else {
       return rawXLeftBorder;
    }
  }

  /**
   * Get tooltip's Y coord
   * @param {Object} tooltip - dom element
   * @return {number} - Y coord
   */
  _y(tooltip) {
    const cursorHeight = 6;
    const rawYOver = this.targetCoords.top - tooltip.offsetHeight - this.yOffset - cursorHeight;
    const rawYUnder = this.targetCoords.bottom + this.yOffset + cursorHeight;

    if(rawYOver < 0) {
      return rawYUnder;
    } else if(rawYUnder > window.innerHeight) {
      return rawYOver;
    } else {
      return this.options?.direction === 'under' ? rawYUnder : rawYOver;
    }
  }

  /**
   * Position tooltip
   * @param {Number/String} x - X coord
   * @param {Number/String} y - Y coord
   */
  _position(x, y) {
    this.tooltip.style.top = `${parseInt(y, 10)}px`;
    this.tooltip.style.left = `${parseInt(x, 10)}px`;
  }
}

export default VanillaTooltip;

