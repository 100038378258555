import { UserAction } from '@/store/modules/users/types';

import store from '@/store';
import { isMobile } from '@/utils';

import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import { RouteName, isRouteAllowed } from '@/permissions';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/wells',
  },
  {
    path: '/well/:wellId?',
    name: RouteName.WELL_DASHBOARD,
    component: () => import('@/views/WellDashboard.vue'),
    props: true,
    children: [
      {
        path: 'settings',
        name: 'well-settings',
        component: () => import('@/views/WellSettings.vue'),
      },
    ],
  },
  {
    path: '/well-summary/:wellId?',
    name: RouteName.WELL_SUMMARY,
    component: () => import('@/views/WellSummary.vue'),
    props: true,
  },
  {
    path: '/wells',
    name: RouteName.WELLS_PAGE,
    component: () => import('@/views/WellsTable.vue'),
  },
  {
    path: '/wells-compare',
    name: RouteName.WELLS_COMPARE,
    component: () => import('@/views/MultiwellsCompare.vue'),
  },
  {
    path: '/notification-center/:createAlert?',
    name: RouteName.NOTIFICATION_CENTER,
    component: () => import('@/views/NotificationCenter.vue'),
    props: true,
  },
  {
    path: '/mwd-tool-check',
    name: RouteName.MWD_TOOL_CHECK,
    component: () => import('@/views/MWDToolCheck.vue'),
    props: true,
  },
  {
    path: '/hydraulics-simulator',
    name: RouteName.HYDRAULICS_SIMULATOR,
    component: () => import('@/views/HydraulicsSimulator.vue'),
  },
  {
    path: '/add-well',
    name: RouteName.ADD_WELL,
    component: () => import('@/views/WellForm.vue'),
  },
  {
    path: '/login',
    name: RouteName.LOGIN,
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/rigs',
    name: RouteName.RIGS,
    component: () => import('@/views/RigsTable.vue'),
  },
  {
    path: '/rig/:rigId?',
    name: RouteName.RIG_SETTINGS,
    component: () => import('@/views/RigSettings.vue'),
    props: true,
  },
  {
    path: '/add-rig',
    name: RouteName.ADD_RIG,
    component: () => import('@/views/RigForm.vue'),
  },
  {
    path: '/motors',
    name: RouteName.MOTORS,
    component: () => import('@/views/MotorsTable.vue'),
  },
  {
    path: '/add-motor',
    name: RouteName.ADD_MOTOR,
    component: () => import('@/views/MotorForm.vue'),
  },
  {
    path: '/motor/:motorId/upload-gpms',
    name: RouteName.MOTOR_UPLOAD_GPMS,
    component: () => import('@/views/MotorGpmsUploader.vue'),
    props: true,
  },
  {
    path: '/user-settings',
    name: RouteName.USER_SETTINGS,
    component: () => import('@/views/UserSettings.vue'),
  },
  {
    path: '/witsml-template',
    name: RouteName.WITSML_TEMPLATE,
    component: () => import('@/views/WitsmlTemplatePage.vue'),
  },
  {
    path: '/admin',
    name: RouteName.ADMIN,
    component: () => import('@/views/AdminPage.vue'),
  },
  {
    path: '/pdf/:token',
    name: RouteName.PDF,
    component: () => import('@/views/Pdf.vue'),
    props: true,
  },
  {
    path: '/pdf-gen/multi-wells',
    name: RouteName.PDF_MULTI_WELLS,
    component: () => import('@/views/PDF/MultiWells.vue'),
    props: true,
  },
  {
    path: '/daily-reports/:wellId?',
    name: RouteName.DAILY_REPORTS,
    component: () => import('@/views/DailyReportsView.vue'),
    props: true,
  },
  {
    path: '/well-not-found',
    name: RouteName.WELL_NOT_FOUND,
    component: () => import('@/views/Error.vue'),
  },
  {
    path: '/mobile',
    name: RouteName.MOBILE_PAGE,
    component: () => import('@/views/MobilePage.vue'),
  },
  {
    path: '*',
    name: RouteName.PAGE_404,
    component: () => import('@/views/404.vue'),
  },
];

export function getRoutePathByName(routeName: RouteName) {
  const route = routes.find((route: RouteConfig) => route.name === routeName);
  if(route) {
    return route.path;
  }
  throw new Error(`Path for route with name "${routeName}" not found`);
}

const checkDevice = (to: Route, from: Route, next: (string?) => void) => {
  if(isMobile() && to.name !== RouteName.MOBILE_PAGE) {
    next('/mobile');
  } else {
    next();
  }
};

const checkAuth = async (to: Route, from: Route, next: (string?) => void) => {
  if(store.getters.user === undefined) {
    await store.dispatch(UserAction.FETCH_USER);
  }
  const canAccess = isRouteAllowed(to.name as RouteName);
  if(!canAccess) {
    next('/login');
  } else {
    next();
  }
};

const redirectFromLoginPageIfLoggedIn = (to: Route, from: Route, next: (string?) => void) => {
  if(to.name === RouteName.LOGIN && store.getters.isLoggedIn) {
    next('/');
  } else {
    next();
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(checkAuth);
router.beforeEach(redirectFromLoginPageIfLoggedIn);
router.beforeEach(checkDevice);

export default router;
