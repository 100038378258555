import { HydraulicsDepthData, HydraulicsTimeData } from '@/store/modules/hydraulics/types';

export type HydraulicsSimulatorData = {
  hydraulicsTimeData: HydraulicsTimeData;
  hydraulicsDepthData: HydraulicsDepthData;
};

export type HydraulicsSimulatorWellSettings = {
  casingDepth: number;
  casingId: number;
  holeId: number;
};

export type HydraulicsSimulatorSurveySettings = {
  md: number;
  tvd: number;
}[];

export type HydraulicsSimulatorBhaSettings = {
  components: {
    id: number;
    od: number;
    length: number;
    type: string;
  }[];
  bit: {
    tfa: number;
    dischargeCoefficient: number;
  };
  motor: {
    flow: {
      flowRate: number;
      pressureLoss: number;
    }[];
  };
};

export type HydraulicsSimulatorMwdRunSettings = {
  mudData: {
    density: number;
    yieldPoint: number;
    plasticViscosity: number;
    // tmp:
    consistency: number;
    fluidBehaviorIndex: number;
  };
};

export type HydraulicsSimulatorFormationTopSettings = {
  porePressure: number;
  fractureGradient: number;
};

export type HydraulicsSimulatorDatapoint = {
  time: number;
  holeDepth: number;
  pumpRate: number;
  blockSpeed: number;
  rpm: number;
};

export enum HydraulicsSimulatorMutation {
  SET_DATA = 'setHydraulicsSimulatorData',
  SET_LOADING = 'setHydraulicsSimulatorLoading',
}

export enum HydraulicsSimulatorAction {
  RUN_SIMULATION = 'runHydraulicsSimulation',
}
